<template>
	<div>
		<div class="question-box">
            <div style="width:50px">问题：</div>
            <div class="q_content">
                {{detail.title}}
                <div v-if="detail.question_cover" class="q_img_content">
                    <el-image 
                    v-for="(item,index) in detail.question_cover.split(',')"
                    :key="index"
                    :src="filterCosImage(item)" 
                    :preview-src-list="[filterCosImage(item)]" 
                    class="image-view" 
                    fit="cover" 
                    />
                </div>
            </div>
        </div>
		<div class="answer-box">
			<div style="width:50px">回答：</div>
			<!-- 图文回答类型 -->
			<div class="answer-box-item" v-if="detail.type == 0">
				{{detail.words}}
				<div v-if="detail.content" class="q_img_content">
                    <el-image 
                    v-for="(item,index) in detail.content.split(',')"
                    :key="index"
                    :src="filterCosImage(item)" 
                    :preview-src-list="[filterCosImage(item)]" 
                    class="image-view" 
                    fit="cover" 
                    />
                </div>
			</div>
			<!-- 语音回答类型 -->
			<div class="answer-box-item" v-if="detail.type == 1">
				<audio :src="defaultUrl+detail.content" controls style="width:100%;display:block;"></audio>
			</div>
			<!-- 视频回答类型 -->
            <div class="answer-box-item" v-if="detail.type == 2">
				<div v-if="detail.content">
					<video :poster="defaultUrl+detail.cover" controls style="width:100%;display:block;">
						<source :src="defaultUrl+detail.content" type="video/mp4">
					</video>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PAGE_CONFIG from '@/utils/config'
	import { filterCosImage } from '@/utils/common.js'
	export default {
		props: ['detail'],
		data() {
			return {
				defaultUrl: PAGE_CONFIG.defaultUrl
			}
		},
		methods:{
			filterCosImage
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.rich-content img {
		width: 100%;
	}
	.question-box div {
		font-size: 16px;
		font-weight: bold;
	}
    .question-box,.answer-box {
        display: flex;
    }
	.q_content,.answer-box-item {
		flex: 1;
	}
	.answer-box {
		margin-top: 30px;
	}
    .q_img_content {
        margin-top: 10px;
    }
	.image-view {
		width: 28%;
        height: 100px;
        margin-right: 20px;
	}
</style>
