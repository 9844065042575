<template>
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 回答举报
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-tabs v-model="type" type="card" @tab-click="tabClick">
			<el-tab-pane label="待审核" name="0">
				<item :activeType="type" type="0" />
			</el-tab-pane>
			<el-tab-pane label="举报成功" name="1">
				<item :activeType="type" type="1" />
			</el-tab-pane>
			<el-tab-pane label="举报不成功" name="2">
				<item :activeType="type" type="2" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import item from './answerItem'
	export default {
		name: 'complain',
		components: {
			item
		},
		data() {
			return {
				type: '0',
			};
		},
		created() {},
		methods: {
			tabClick(e) {
				//切换type
				this.type = e.props.name
			}
		}
	}
</script>
<style scoped>

</style>
